import { render, staticRenderFns } from "./salesReport.vue?vue&type=template&id=61463547&scoped=true&"
import script from "./salesReport.vue?vue&type=script&lang=js&"
export * from "./salesReport.vue?vue&type=script&lang=js&"
import style0 from "./salesReport.vue?vue&type=style&index=0&id=61463547&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61463547",
  null
  
)

export default component.exports