<!-- 销售报表 -->
<template>
  <div class="companySales">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>销售负责人:</span>
        <el-input
          v-model="params.keyword"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            getIncomeList();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <!-- 导出 -->
    <el-button class="common-screen-btn" type="primary">导出报表</el-button>
    <el-button type="text">报表设置</el-button>

    <!-- 列表 -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" :formatter="tableColumn" label="日期"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="销售负责人"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="客户池客户总数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="客户公海客户总数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="新增客户数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="捞取客户数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="销售机会总数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="新增销售机会数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="当前订单数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="收入总金额"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="新增订单数"> </el-table-column>
      <el-table-column prop="date" :formatter="tableColumn" label="历史欠款金额(月)"> </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn, Config } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate";

export default {
  name: "companySales",
  components: {
    Breadcrumb,
    CommonDate
  },
  props: {},
  data() {
    return {
      tableColumn,
      Config,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "自定义报表", isLink: false },
        { title: "销售报表", isLink: false }
      ],
      params: {
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      tableData: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 数据汇总弹窗end
    commonGetDate(startTime, endTime) {
      this.params.startDate = startTime;
      this.params.endDate = endTime;
      //   this.getIncomeList();
    },
    resetBtn() {
      (this.params = {
        pageNum: 1,
        pageSize: 10
      }),
        this.$refs.commonReset.resetFun();
      //   this.getQuotesList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }

      // this.getIncomeList();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.companySales {
  text-align: left;
}
</style>
